import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-recaptcha';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import logo from './logo.png';
import Modal from 'react-modal';

Modal.setAppElement('#root'); 

function App() {
  const [referer, setReferer] = useState('');
  const [uid, setUid] = useState(uuidv4());
  const [rni, setRni] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ rni: '', email: '', captcha: '' });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [showForm, setShowForm] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessageComponent, setModalMessageComponent] = useState(null);
  const [isError, setIsError] = useState(false);

  const specificUrl = 'https://forms.gle/y5A4vnwf6hGsJr9b6';  

  const validateRni = (rni) => {
    if (!rni) {
      return 'El RNI es requerido';
    } else if (!/^\d{1,8}$/.test(rni)) {
      return 'El RNI debe ser un número de máximo 8 caracteres';
    }
    return '';
  };

  const validateEmail = (email) => {
    if (!email) {
      return 'El correo electrónico es requerido';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return 'El correo electrónico debe ser válido';
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    const rniError = validateRni(rni);
    const emailError = validateEmail(email);

    if (rniError || emailError) {
      setErrors({ rni: rniError, email: emailError });
    } else {
      setErrors({ rni: '', email: '' });
      // Aquí puedes manejar el envío del formulario
      console.log('RNI:', rni);
      console.log('Email:', email);
      console.log('UID:', uid);

      fetch('https://ticket.citior.org.bo/api/tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nombre_sorteo: 'ENCUESTA_INGENIEROS_CITI_ORURO_JUNIO_2024',
          rni,
          correo_electronico: email,
          num_ticket: uid,
        }),
      })
      .then(response => response.json().then(data => ({ status: response.status, data })))
      .then(({ status, data }) => {
        if (status === 201) {
          setModalMessageComponent(
            <>
              <p>Gracias por realizar el cuestionario, ticket registrado correctamente.</p>
              <p>RNI: {rni}</p>
              <p>Correo: {email}</p>
              <p className="highlight-text">Revisa tu bandeja de entrada o carpeta spam en unos minutos.</p>
            </>
            );
        } else {
          setModalMessageComponent(
            <>
              <p style={{ color: 'red' }}>Error al crear el ticket.</p>
              <p style={{ color: 'red' }}>{data.message}</p>
            </>
          );
        }
        setModalIsOpen(true);
      })
      .catch((error) => {
        console.error('Error al crear el ticket:', error);
        setModalMessageComponent(
          <>
            <p style={{ color: 'red' }}>Error al crear el ticket: {error.message}</p>
            <p>RNI: {rni}</p>
            <p>Correo electrónico: {email}</p>
          </>
        );
        setModalIsOpen(true);    
      });
    }
  };

  const handleCaptchaVerify = () => {
    setIsCaptchaVerified(true);
  };

  const closeModal = () => {
    window.location.href = 'https://citior.org.bo';
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refererFromQuery = queryParams.get('referer');
    setReferer(refererFromQuery);

        // Clear URL parameters
        if (window.location.search) {
          const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
          window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    
    
    if (refererFromQuery === specificUrl) {
      console.log(`Llegando desde ${specificUrl}`);
    } else {
      console.log(`Debiste llegar desde ${specificUrl}`);
    }
  }, []);

  return (
    <div className="app-container">

<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal de Respuesta"
        className="modal"
        overlayClassName="modal-overlay"
      >

<h2>Resultado</h2>
        {modalMessageComponent}
        <button onClick={closeModal}>Cerrar</button>
      </Modal>
                  
      <div className="container">           
      <div className="ticket">
      <div className="header">
        <img src={logo} alt="CITI Oruro" className="logo" />
      </div>
        <h2>REGISTRO DE TICKET</h2>        
        <p className="justified-text">
          Bienvenido al sistema de registro de tickets para: "Encuesta CITI Oruro para profesionales en tecnologías de la información - Junio 2024". Por favor, siga la siguiente información para registrar su ticket.</p>
          {!referer || referer !== specificUrl ? (
            <div>
              <p className="justified-text">Para activar el ticket debe realizar el formulario primero.</p>
              <p className="justified-text">Link del formulario: <a href="https://forms.gle/y5A4vnwf6hGsJr9b6" target="_blank" rel="noopener noreferrer">https://forms.gle/y5A4vnwf6hGsJr9b6</a></p>
            </div>
          ) : (
            <div>
          <ul className="left-text">
            <li>No actualice la página, sino perderá el ticket generado.</li>
            <li>Verifica tu RNI y correo electrónico.</li>
            <li>Luego de enviar le llegará el ticket generado a su correo electrónico.</li>
          </ul>
        
        <hr />
        <p className="ticket-info"><strong>Ticket generado:</strong> {uid}</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="rni">Ingrese su RNI:</label>
            <input
              type="text"
              id="rni"
              value={rni}
              onChange={(e) => setRni(e.target.value)}
              required
            />
            {errors.rni && <p className="error">{errors.rni}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Ingrese su correo electrónico:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <button type="submit">Enviar</button>
        </form></div>
)}
      </div>
      </div> 

      <div className="footer">
        <p>Copyright © CITI ORURO 2024.</p>
      </div>

      
    </div>
    
  );
}

export default App;

